<template>
    <div class="data-store-container">
        <div class="card-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
      </el-breadcrumb>
        </div>
        <div class="data-store-content">
            <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="普通批招生计划" name="1">
                <Plan/>
                <Remark/>
            </el-tab-pane>
            <el-tab-pane label="提前批招生计划" name="2">
                <tqPPlan/>
                <Remark/>
            </el-tab-pane>
            <el-tab-pane label="批次控制线" name="3">
                <Kzx/>
                <Remark/>
            </el-tab-pane>
            <el-tab-pane label="一分段" name="4">
                <Yfd/>
                <Remark/>
            </el-tab-pane>
            <el-tab-pane label="投档线" name="5">
                <Tdx/>
                <Remark/>
            </el-tab-pane>
            <el-tab-pane label="2022年双一流" name="6">
                <syl/>
                <Remark/>
            </el-tab-pane>
            <el-tab-pane label="专业选科要求" name="7">
                <Zyxkyq/>
                <Remark/>
            </el-tab-pane>
            <!-- <el-tab-pane label="招生政策" name="8">
                <zszcArticle/>
            </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Remark from '../../components/remark.vue';
import Plan from './plan/queryPlan.vue';
import Kzx from './kzx/queryKzx.vue';
import Yfd from './yfd/queryYfd.vue';
import Tdx from './tdx/queryTdx.vue';
import Syl from './syl/querySyl.vue';
import Zyxkyq from './zyxkyq/queryZyxkyq.vue';
import tqPPlan from './tqpPlan/queryPlan.vue';
// import zszcArticle from './zszcArticle/articleList.vue';
export default {
props:['sxkmChangeReloadDateHome'],
data(){
    return{
        activeName:"1",
        reloadTimer:null
    };
},
mounted(){
this.reloadTimer=setInterval(() => {
    if(this.sxkmChangeReloadDateHome&&this.sxkmChangeReloadDateHome==='true'){
        clearInterval(this.reloadTimer);
        this.$router.push({
            path:'/datahome',
            query:{
                time:(new Date().getMilliseconds())
            }
        });
        location.reload();
    }
}, 1000);
},
components:{
    Remark,
    Plan,
    Kzx,
    Yfd,
    Tdx,
    Syl,
    Zyxkyq,
    tqPPlan
}
};
</script>
<style lang="less" scoped>
.data-store-container{
    min-width: 1200px;
    margin: 0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .data-store-content{
        width: 1100px;
        margin: 0 auto;
    }
    .remark-container{
        width: 1100px;
        margin: 20px auto;
        box-sizing: border-box;
        padding: 20px;
        color: #606266;
        background-color: #fff;
    }
}
</style>
