<template>
    <div>
        <div>
            <div class="plan-container">
                <div class="plan-title">普通批招生计划</div>
                <div class="plan-condition">
                    <el-form :inline="true">
                    <el-form-item label="选择批次">
                        <el-select style="width:150px;" v-model="planParam.pc">
                        <el-option v-if="pcList.length===0" disabled value="-1">
                            <i class="el-icon-loading" style="font-size: 16px;"></i>
                        </el-option>
                        <template v-if="pcList.length>0">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in pcList"
                        :key="index" :label="item.name" :value="item.value">
                        </el-option>
                        </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择年度">
                        <el-select style="width:150px;" v-model="planParam.nd">
                            <el-option v-if="ndList.length===0" disabled value="-1">
                                <i class="el-icon-loading" style="font-size: 16px;"></i>
                            </el-option>
                            <template v-if="ndList.length>0">
                            <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in ndList"
                            :key="index" :label="item.name" :value="item.value">
                            </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业名称">
                        <el-input style="width:150px;" v-model="planParam.zymc"></el-input>
                    </el-form-item>
                    <el-form-item label="院校名称">
                        <el-input style="width:150px;" v-model="planParam.yxmc"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getPlan">搜索</el-button>
                    </el-form-item>
                </el-form>
                </div>
                <div>
                    <!-- <div>计划共招人</div> -->
                    <div id="planDiv">
                        <el-table :data="planList.list" border height="560"
                        :header-cell-style="{'background-color':'#B0E2FF','color': '#3B3B3B','border':'1px solid #0099FF','text-align':'center'}" :cell-style="{'border':'1px solid #0099FF','text-align':'center'}">
                            <el-table-column show-overflow-tooltip label="年度" prop="nd" width="60" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="院校名称" prop="yxmc" width="180" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="批次" prop="pc" width="70" :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.pc===0">本科提前批</span>
                                    <span v-if="scope.row.pc===6">本科批</span>
                                    <span v-if="scope.row.pc===7">专科批</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="专业组" prop="zyz" width="70" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="科目要求" prop="zxkmyq" width="190" :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.kl==='1'"><span style="color: #ff6900;">首选</span> 历史</span>
                                    <span v-if="scope.row.kl==='2'"><span style="color: #ff6900;">首选</span> 物理</span>
                                    &nbsp;<span>{{scope.row.zxkmyq}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="开设专业" prop="zymc" width="300" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="计划数" prop="jhs" width="70" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="代号" prop="zydh" width="60" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="收费" prop="sfbz" width="70" :resizable="false"></el-table-column>
                        </el-table>
                        <el-pagination v-if="false"></el-pagination>
                    </div>
                    <div v-if="planFlag" class="plan-page-div">
                        <el-pagination
                        @size-change="handlePlanSizeChange"
                        @current-change="handlePlanCurrentChange"
                        :current-page="planList.currnet"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="planList.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="planList.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        planList:{
            list:[],
            currnet:1,
            pageSize:10,
            total:0
        },
        pcList:[
        ],
        planParam:{
            nd:"",
            pc:"",
            yxmc:"",
            zymc:"",
            kl:"",
            pageNum:1,
            pageSize:10
        },
        ndList:[],
        planFlag:true
    };
},
methods:{
    async getPlan(){
       await this.loadData();
    },
    async loadData(pageNum=1,pageSize=10){
        this.planFlag=false;
        const loading=this.$loading({fullscreen:true,target:document.getElementById("planDiv"),text:"正在加载中"});
        this.planParam.pageNum=pageNum;
        this.planParam.pageSize=pageSize;
        const res=await this.$http.postHttp("/lnsj/zsjh",this.planParam);
        if(res.data.code===200){
            this.planList.list=res.data.obj.records;
            this.planList.currnet=pageNum;
            this.planList.total=res.data.obj.total;
            this.planList.pageSize=pageSize;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
        this.planFlag=true;
    },
   async handlePlanSizeChange(pageSize){
        await this.loadData(this.planParam.pageNum,pageSize);
    },
   async handlePlanCurrentChange(pageNum){
        await this.loadData(pageNum,this.planParam.pageSize);
    },
    async getPcCondition(){
        const res=await this.$http.getHttp("/lnsj/zsjhpcs");
        if(res.data.code===200){
            const pcs=res.data.obj;
            for(let i=0;i<pcs.length;i++){
                if(pcs[i].pcmc){
                    this.pcList[i]={};
                    this.pcList[i].name=pcs[i].pcmc;
                    this.pcList[i].value=pcs[i].pc+"";
                }
            };
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    async getNdCondition(){
        const res=await this.$http.getHttp("/lnsj/zsjhNds");
        if(res.data.code===200){
            const nds=res.data.obj;
            const ndArr=[];
            for(let i=0;i<nds.length;i++){
                if(nds[i]){
                    ndArr.push({name:nds[i],value:nds[i]});
                }
            }
            this.ndList=ndArr;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
    this.planParam.kl=this.userInfo.sxkm;
    await this.getPlan();
    await this.getPcCondition();
    await this.getNdCondition();
}
};
</script>
<style lang="less" scoped>
.plan-container{
    width:1100px;
    margin:0 auto;
    .plan-title{
        width:1100px;
        display: flex;
        justify-content: center;
        align-content: center;
        margin:10px 0;
        font-size: 18px;
        font-weight: bold;
    }
    .plan-page-div{
        width:800px;
        margin:20px auto;
    }
}
</style>
