<template>
    <div class="school-detail-wraper">
        <div class="card-top">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-top">
            <div class="top-logo">
                <img v-if="detail.gbdm"
                :src="'http://img.gkzyydt.com/upload/img/logo/'+detail.gbdm+'.jpg'" :alt="detail.yxmc" width="100%">
            </div>
            <div class="top-mid">
                <div class="mid-school-name">
                    <h3>
                        {{ detail.yxmc }}
                    <template v-if="detail.sf985===1">
                        <img src="../assets/images/ico2.png" title="985">
                    </template>
                    <template v-if="detail.sf211===1">
                        <img src="../assets/images/ico1.png" title="211">
                    </template>
                    <template v-if="detail.sfsyl===1">
                        <img src="../assets/images/ico8.png" title="双一流">
                    </template>
                    <template v-if="detail.sf101===1">
                        <img src="../assets/images/ico9.png" title="101">
                    </template>
                    <template v-if="detail.yfyjsy===1">
                        <img src="../assets/images/ico3.png" title="研究生院">
                    </template>
                    </h3>
                    <el-button  type="primary" size="mini" @click="closeWin" plain round>关闭</el-button>
                </div>
                <div class="mid-school-nature">
                    <div class="nature-item">
                        <span>{{detail.yymc}}</span>
                    </div>
                    <!-- <div class="nature-item">
                        <span class="school-link">网址：{{detail.wz}}</span>
                    </div> -->
                </div>
            </div>
            <div></div>
        </div>
        <div class="detail-main">
            <el-tabs v-model="activeName" tab-position="left" type="card">
            <el-tab-pane label="专业组详情" name="1">
                <div class="schoolIntro_con2">
                    <div class="catTitle2">
                        <h2>专业组历年数据对比</h2>
                    </div>
                    <div class="txt"  id="lqTab">
                        <table class="info-data">
                            <tr>
                                <th rowspan="2">专业组</th>
                                <th rowspan="2">年度</th>
                                <th colspan="6">录取分</th>
                                <th colspan="3">等效分</th>
                                <th>计划人数</th>
                            </tr>
                            <tr>
                                <th>最低分</th>
                                <th>平均分</th>
                                <th>最高分</th>
                                <th>投档线</th>
                                <th>线差</th>
                                <el-tooltip placement="top">
                                <div slot="content">我的省排名{{userInfo.spm}}</div>
                                <th>入档位次</th>
                                </el-tooltip>
                                <th>最低分</th>
                                <th>平均分</th>
                                <th>最高分</th>
                                <th>今年</th>
                            </tr>
                                <!-- <tr v-for="(item,index) in zyzTotals.sub" :key="index">
                                <td :rowspan="zyzRowspan" v-if="index===0&&type==='1'">
                                    <a href="javascript:void(0)" style="color:#2186de;">[{{zyzTotals.zyz}}]</a>
                                    <span style="display:block;width:41px;height:5px"></span>
                                    <div class="collectBtn" v-if="zyzTotals.sfsc===0" @click="addCollect(zyzTotals.zyz,zyzTotals.sfsc)">加入收藏</div>
                                    <div class="collectBtn" v-if="zyzTotals.sfsc===1" @click="addCollect(zyzTotals.zyz,zyzTotals.sfsc)">取消收藏</div>
                                </td>
                                <td :rowspan="zyzRowspan" v-if="index===0&&type==='2'">
                                    <a href="javascript:void(0)" style="color:black;">{{zyzTotals.zyz}}</a>
                                </td>
                                <td>{{item.nd?item.nd:item.nd===0?'平均分':'-'}}</td>
                                <td>{{!item.zdf?'-':item.zdf}}</td>
                                <td>{{!item.pjf?'-':item.pjf}}</td>
                                <td>{{!item.zgf?'-':item.zgf}}</td>
                                <td>{{!item.tdx?'-':item.tdx}}</td>
                                <td>{{!item.xc?'-':item.xc}}</td>
                                <td>{{!item.rdwc?'-':item.rdwc}}</td>
                                <td v-if="item.nd===0" class="yuceTdx">{{!item.zdtwf?'-':item.zdtwf}}</td>
                                <td v-if="item.nd!==0">{{!item.zdtwf?'-':item.zdtwf}}</td>
                                <td>{{!item.pjtwf?'-':item.pjtwf}}</td>
                                <td>{{!item.zgtwf?'-':item.zgtwf}}</td>
                                <td :rowspan="zyzRowspan" v-if="index===0">{{zyzTotals.jnjhs}}</td>
                                </tr> -->
                                <tbody v-for="(item0,index0) in zyzTotals.list" :key="index0">
                                <tr v-for="(item,index) in item0.sub" :key="index">
                                <td :rowspan="zyzRowspan" v-if="index===0&&type==='1'">
                                    <a href="javascript:void(0)" style="color:#2186de;"
                                    @click="toZyzDetail(detail.gbdm,detail.yxxh,detail.yxmc,item0.zyz,detail.pc)">[{{item0.zyz}}]</a>
                                    <span style="display:block;width:41px;height:5px"></span>
                                    <div class="collectBtn" :loading="collectLoading" v-if="item0.isSc===0" @click="addCollect(item0.zyz,zyzTotals.sfsc)">加入收藏</div>
                                    <div class="collectBtn" :loading="collectLoading" v-if="item0.isSc===1" @click="addCollect(item0.zyz,zyzTotals.sfsc)">取消收藏</div>
                                </td>
                                <td :rowspan="zyzRowspan" v-if="index===0&&type==='2'">
                                    <a href="javascript:void(0)" style="color:black;">{{item0.zyz}}</a>
                                </td>
                                <td>{{item.nd?item.nd:item.nd===0?'平均分':'-'}}</td>
                                <td>{{!item.zdf?'-':item.zdf}}</td>
                                <td>{{!item.pjf?'-':item.pjf}}</td>
                                <td>{{!item.zgf?'-':item.zgf}}</td>
                                <td>{{!item.tdx?'-':item.tdx}}</td>
                                <td>{{!item.xc?'-':item.xc}}</td>
                                <td>{{!item.rdwc?'-':item.rdwc}}</td>
                                <td v-if="item.nd===0" class="yuceTdx">{{!item.zdtwf?'-':item.zdtwf}}</td>
                                <td v-if="item.nd!==0">{{!item.zdtwf?'-':item.zdtwf}}</td>
                                <td>{{!item.pjtwf?'-':item.pjtwf}}</td>
                                <td>{{!item.zgtwf?'-':item.zgtwf}}</td>
                                <td :rowspan="zyzRowspan" v-if="index===0">{{item.jnjhs}}</td>
                                </tr>
                                </tbody>
                        </table>
                    </div>
                    <div class="catTitle2" v-if="type==='2'">
                        <h2>专业详情表</h2>
                    </div>
                    <div class="txt order" style="width:790px;" v-if="type==='2'">
                    <div class="yx_zy_group">
                        <div class="group_title">
                            <div class="zyz_title">专业组{{zyz}}</div>
                            <div class="collect" :loading="collectLoading" v-if="isSc===0" @click="addCollect(zyz,zyzTotals.sfsc)">加入收藏</div>
                            <div class="collect" :loading="collectLoading" v-if="isSc===1" @click="addCollect(zyz,zyzTotals.sfsc)">取消收藏</div>
                        </div>
                        <div class="group_content" v-for="(item,index) in zyTotals"
                        :key="index">
                            <div class="zy_name" :title="item.zybz">
                                <span class="index">{{index+1}}</span>
                                {{item.zymc}}
                                <span class="tip">{{item.zybz}}</span>
                            </div>
                            <div class="zy_attrs">
                                <div class="attr-item">
                                    <span v-if="item.sxkm===2">首选科目：物理</span>
                                    <span v-if="item.sxkm===1">首选科目：历史</span>
                                </div>
                                <div class="attr-item">
                                    <span>再选科目要求：{{item.zxkmyq}}</span>
                                </div>
                                <div class="attr-item">
                                    <span>今年计划数：{{item.jnjhs}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="sti-tbl-container">
                        <div class="sti-tbl-header" style="padding-right:17px;">
                        <table></table>
                        </div>
                        <div class="sti-tbl-body">
                            <table></table>
                        </div>
                    </div> -->
                    <div id="zyData">
                        <el-table :data="zyData" max-height="770" :span-method="objectSpanMethod"
                        :header-cell-style="{'background-color':'#f5f7fa','border': '1px solid #09F',
                        'color': '#909399','text-align':'center'}" :cell-class-name="getCellClass"
                        :cell-style="{'border':'1px solid #09F','text-align':'center'}">
                        <el-table-column show-overflow-tooltip label="专业名称" prop="zymc" width="125" :resizable="false"></el-table-column>
                        <el-table-column show-overflow-tooltip label="一流学科" prop="zdzy" width="55" :resizable="false">
                            <template slot-scope="scope">
                                <img style="width:25px;height:25px;" src="../assets/images/gou.png" v-if="scope.row.zdzy===1"/>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="年份" width="70" prop="nd" :resizable="false">
                            <template slot-scope="scope">
                                <span v-if="scope.row.nd!==0">{{scope.row.nd}}</span>
                                <span v-if="scope.row.nd===0">平均</span>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="录取分" :resizable="false">
                            <el-table-column label="最低" width="55" prop="zdf" :resizable="false"></el-table-column>
                            <el-table-column label="平均" width="55" prop="pjf" :resizable="false"></el-table-column>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业线差" width="55" prop="xc" :resizable="false"></el-table-column>
                        <el-table-column show-overflow-tooltip label="专业入档位次" width="70" prop="rdwc" :resizable="false"></el-table-column>
                        <el-table-column show-overflow-tooltip label="等效分" :resizable="false">
                            <el-table-column label="最低" width="55" prop="zdtwf" :resizable="false"></el-table-column>
                            <el-table-column label="平均" width="55" prop="pjtwf" :resizable="false"></el-table-column>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="往年批次" width="80" prop="pc" :resizable="false">
                            <template slot-scope="scope">
                                <span v-if="scope.row.pc===6">本科批</span>
                                <span v-if="scope.row.pc===7">专科批</span>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="计划人数" :resizable="false">
                            <el-table-column label="历年" width="55" prop="jhs" :resizable="false"></el-table-column>
                            <el-table-column label="今年" width="55" prop="jnjhrs" :resizable="false"></el-table-column>
                        </el-table-column>
                    </el-table>
                    </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="院校简介" name="2">
                <div style="margin-left:15px;">
                    <div class="schoolIntro_con">
                    <div class="intro"  style="text-indent: 28px;" id="jjDiv">{{ detail.jj }}</div>
                    <div class="read-more" style="text-align: right;">
                    <a href="javascript:void(0)" @click="watchAndStop">{{detailTxt}}</a>
                    <div class="baseInfo clearfix">
                        <ul class="baseInfo_left">
                            <li class="biItem">
                                <span class="t">创建时间</span>
                                <div class="c" id="cjsj">{{detail.jxsj}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">隶属于</span>
                                <div class="c" id="ls">{{detail.ls}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">学生人数</span>
                                <div class="c" id="xsrs">{{detail.xsrs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">院士人数</span>
                                <div class="c" id="ysrs">{{detail.ysrs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">男生比例</span>
                                <div class="c" id="nsbl">{{detail.nsbl}}</div>
                            </li>
                        </ul>
                        <ul class="baseInfo_right">
                            <li class="biItem">
                                <span class="t">一流学科</span>
                                <div class="c" id="zdxk">
                                    {{detail.ylxkSize}}
                                </div>
                            </li>
                            <li class="biItem">
                                <span class="t">学校类型</span>
                                <div class="c" id="xxlx">
                                    {{detail.xxlbmc}}
                                </div>
                            </li>
                            <li class="biItem">
                                <span class="t">博士点个数</span>
                                <div class="c" id="bsdgs">{{detail.bsdgs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">硕士点个数</span>
                                <div class="c" id="ssdgs">{{detail.ssdgs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">女生比例</span>
                                <div class="c" id="nvsbl">{{detail.nvsbl}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="catTitle">
                        <h2>学费信息</h2>
                    </div>
                    <div class="txt" style="text-indent:28px;" id="xfxx" v-html="detail.xfxx">
                    </div>
                    <div class="catTitle">
                        <h2>就业情况</h2>
                    </div>
                    <div class="txt" style="text-indent:28px;" id="jyqk" v-html="detail.jyqk">
                    </div>
                    <div class="txt">
                        以上数据由金榜志愿通<a href></a>汇总整理。
                    </div>
                    </div>
                </div>
                <div class="schoolIntro_side">
                    <div class="schoolIntro_sideBd">
                        <div class="pics">
                            <div class="schoolPic_slide">
                                <ul>
                                    <li>
                                        <img id="yxtb_xgt" :src="'http://img.gkzyydt.com/upload/img/xiaoguo/'+detail.gbdm+'8.jpg'" :alt="detail.yxmc"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="infos">
                            <ul>
                                <li>
                                    <label>所在城市：</label>
                                    <font id="ss">{{detail.szdqmc}}</font>
                                </li>
                                <li>
                                    <label>学校地址：</label>
                                    <font id="xxdz">{{detail.dz}}</font>
                                </li>
                                <li>
                                    <label>招生电话：</label>
                                    <font id="zsdh">{{detail.dh}}</font>
                                </li>
                                <li>
                                    <label>电子邮箱：</label>
                                    <font id="dzyx">{{detail.dzyx}}</font>
                                </li>
                            </ul>
                            <div class="website">
                                <a :href="detail.wz" target="_blank" class="a1">进入官网</a>
                                <a :href="detail.zswz" target="_blank" class="a2">进入招生网站</a>
                            </div>
                        </div>
                        <!-- <div class="hotschool">
                            <div class="hd"></div>
                            <div class="hd"></div>
                        </div> -->
                    </div>
                </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="一流学科" name="3">
                <div class="schoolIntro_con22">
                    <div class="majorCon2">
                        <h3>一流学科</h3>
                    </div>
                    <div class="majorCon1" id="gjppzyDiv">
                        <ul class="list clearfix" id="gjppzy">
                            <li v-for="(item,index) in detail.ylxks" :key="index">
                                ● <a href="javascript:void(0)" :zy_zyid="item.zydm" @click="toZyDetail(item.zydm)">{{item.zymc}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="招生章程" name="4">
                <div class="schoolIntro_con23">
                    <div class="majorCon2">
                        <h3>招生章程</h3>
                    </div>
                    <div class="txt" id="zsjz" v-html="detail.zszc"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
// import $ from 'jquery';
export default {
props:["type"],
data(){
    return{
        activeName:"1",
        detail:{
            logo:"",
            dz:"",
            yxmc:"",
            wz:"",
            jxsj:"",
            ls:"",
            xsrs:"",
            bsdgs:"",
            ssdgs:"",
            ysrs:"",
            nsbl:"",
            nvsbl:"",
            jj:"",
            lsjj:"",
            xfxx:"",
            jyqk:"",
            szdqmc:"",
            dh:"",
            dzyx:"",
            ylxks:[],
            zswz:"",
            zszc:"",
            yymc:"",
            sf985:0,
            sf211:0,
            yfyjsy:0,
            sfsyl:0,
            gbdm:"",
            xxlbmc:"",
            xxlb:"",
            ylxkSize:"",
            yxxh:"",
            pc:""
        },
        zyzTotals:{
        },
        zyData:[],
        xh:"",
        scZyzSize:0,
        isSc:0,
        zyz:"",
        collectLoading:false,
        zyzRowspan:"",
        maxwidth:300,
        zyTotals:{},
        detailTxt:"查看详情 >>"
    };
},
methods:{
    // 获取院校详情数据
    async getSchoolDeatil(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("lqTab"),text:"正在加载中"});
        const { gbdm, zyz = null,pc,yxmc,yxxh } = this.$route.query;
        const res=await this.$http.postHttp("/yxcx/getYxDetail",{
            gbdm,
            zyz,
            pc,
            yxmc,
            yxxh
        });
        if(res.data.code===200){
            this.detail.logo=res.data.obj.yxml.logo;
            this.detail.dz=res.data.obj.yxml.dz;
            this.detail.wz=res.data.obj.yxml.xxwz;
            this.detail.yxmc=res.data.obj.yxmcStr;
            this.detail.jj=res.data.obj.yxml.jj;
            this.detail.jxsj=res.data.obj.yxml.jxsj;
            this.detail.ls=res.data.obj.yxml.ls;
            this.detail.xsrs=res.data.obj.yxml.xsrs;
            this.detail.bsdgs=res.data.obj.yxml.bsdgs;
            this.detail.ysrs=res.data.obj.yxml.ysrs;
            this.detail.ssdgs=res.data.obj.yxml.ssdgs;
            this.detail.nsbl=res.data.obj.yxml.nsbfb;
            this.detail.nvsbl=res.data.obj.yxml.nvsbfb;
            this.detail.xfxx=res.data.obj.yxml.xfxx;
            this.detail.jyqk=res.data.obj.yxml.jyqk;
            this.detail.ylxks=res.data.obj.zdzys;
            this.detail.szdqmc=res.data.obj.yxml.szdqmc;
            this.detail.dh=res.data.obj.yxml.dh;
            this.detail.dzyx=res.data.obj.yxml.dzyj;
            this.detail.zswz=res.data.obj.yxml.zswz;
            this.detail.zszc=res.data.obj.yxml.zszc;
            this.detail.yymc=res.data.obj.yxml.yxywmc;
            this.detail.sf985=res.data.obj.yxml.sf985;
            this.detail.sf211=res.data.obj.yxml.sf211;
            this.detail.sfsyl=res.data.obj.yxml.sfsyl;
            this.detail.yfyjsy=res.data.obj.yxml.yfyjsy;
            this.detail.sf101=res.data.obj.yxml.sf101;
            this.detail.gbdm=res.data.obj.yxml.gbdm;
            this.detail.xxlb=res.data.obj.yxml.xxlb;
            this.detail.xxlbmc=res.data.obj.yxml.xxlbmc;
            this.detail.ylxkSize=res.data.obj.zdzysSize;
            this.detail.yxxh=res.data.obj.yxxhStr;
            this.detail.pc=pc;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        const res2=await this.$http.postHttp("/yxcx/getLqxq",{
            kl:this.userInfo.kl,
            pc,
            yxxh,
            zxkma:this.userInfo.zxkma,
            zxkmb:this.userInfo.zxkmb,
            zyz
        });
        if(res2.data.code===200){
            // this.zyzTotals=res2.data.obj.zyzTotals[0];
            const len=res2.data.obj.zyzTotals.length;
            const zyzs=res2.data.obj.zyzTotals;
            for(let i=0;i<len;i++){
                const zyz=zyzs[i].zyz;
                const isSc=res2.data.obj.zyz[zyz].isSc;
                if(isSc){
                    zyzs[i].isSc=1;
                }else{
                    zyzs[i].isSc=0;
                }
            }
            this.zyzTotals.list=res2.data.obj.zyzTotals;
            // this.zyzTotals.zyz=res2.data.obj.zyz;
            this.zyzRowspan=res2.data.obj.zyzTotals[0].sub.length;
            // this.zyzRowspan=4;
            // this.zyzTotals.jnjhs=res2.data.obj.zyzTotals[0].sub[0].jnjhs;
            // this.zyzTotals.sfsc=res2.data.obj.sfsc;
        }else if(res2.data.code===500){
            if(res2.data.message){
                this.$message({message:res2.data.message,type:"error"});
            }
        }else if(res2.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res2.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 获取院校专业组详情数据
    async getZyzDeatil(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("lqTab"),text:"正在加载中"});
        const loading2=this.$loading({fullscreen:true,target:document.getElementById("zyData"),text:"正在加载中"});
        const { gbdm, zyz = null,pc,yxmc,yxxh } = this.$route.query;
        const res=await this.$http.postHttp("/yxcx/getYxZyzDetail",{
            gbdm,
            zyz,
            pc,
            yxmc,
            yxxh
        });
        if(res.data.code===200){
            this.detail.logo=res.data.obj.yxml.logo;
            this.detail.dz=res.data.obj.yxml.dz;
            this.detail.wz=res.data.obj.yxml.xxwz;
            this.detail.yxmc=res.data.obj.yxmcStr;
            this.detail.jj=res.data.obj.yxml.jj;
            this.detail.jxsj=res.data.obj.yxml.jxsj;
            this.detail.ls=res.data.obj.yxml.ls;
            this.detail.xsrs=res.data.obj.yxml.xsrs;
            this.detail.bsdgs=res.data.obj.yxml.bsdgs;
            this.detail.ysrs=res.data.obj.yxml.ysrs;
            this.detail.ssdgs=res.data.obj.yxml.ssdgs;
            this.detail.nsbl=res.data.obj.yxml.nsbfb;
            this.detail.nvsbl=res.data.obj.yxml.nvsbfb;
            this.detail.xfxx=res.data.obj.yxml.xfxx;
            this.detail.jyqk=res.data.obj.yxml.jyqk;
            this.detail.ylxks=res.data.obj.zdzys;
            this.detail.szdqmc=res.data.obj.yxml.szdqmc;
            this.detail.dh=res.data.obj.yxml.dh;
            this.detail.dzyx=res.data.obj.yxml.dzyj;
            this.detail.zswz=res.data.obj.yxml.zswz;
            this.detail.zszc=res.data.obj.yxml.zszc;
            this.detail.yymc=res.data.obj.yxml.yxywmc;
            this.detail.sf985=res.data.obj.yxml.sf985;
            this.detail.sf211=res.data.obj.yxml.sf211;
            this.detail.sfsyl=res.data.obj.yxml.sfsyl;
            this.detail.yfyjsy=res.data.obj.yxml.yfyjsy;
            this.detail.sf101=res.data.obj.yxml.sf101;
            this.detail.gbdm=res.data.obj.yxml.gbdm;
            this.detail.xxlb=res.data.obj.yxml.xxlb;
            this.detail.xxlbmc=res.data.obj.yxml.xxlbmc;
            this.detail.ylxkSize=res.data.obj.zdzysSize;
            this.detail.yxxh=res.data.obj.yxxhStr;
            this.detail.pc=pc;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        const res2=await this.$http.postHttp("/yxcx/getLqxq",{
            kl:this.userInfo.kl,
            pc,
            yxxh,
            zxkma:this.userInfo.zxkma,
            zxkmb:this.userInfo.zxkmb,
            zyz
        });
        if(res2.data.code===200){
            // this.zyzTotals=res2.data.obj.zyzTotals[0];
            const len=res2.data.obj.zyzTotals.length;
            const zyzs=res2.data.obj.zyzTotals;
            for(let i=0;i<len;i++){
                const zyz=zyzs[i].zyz;
                const isSc=res2.data.obj.zyz[zyz].isSc;
                if(isSc){
                    zyzs[i].isSc=1;
                }else{
                    zyzs[i].isSc=0;
                }
            }
            if(len===1){
                const isSc=res2.data.obj.zyz[zyzs[0].zyz].isSc;
                if(isSc){
                    this.isSc=1;
                }else{
                    this.isSc=0;
                }
            }
            console.log(res2);
            this.zyzTotals.list=res2.data.obj.zyzTotals;
            // this.zyzTotals.zyz=res2.data.obj.zyz;
            this.zyzRowspan=res2.data.obj.zyzTotals[0].sub.length;
            this.zyz=res2.data.obj.zyzTotals[0].zyz;
            this.zyTotals=res2.data.obj.zyz[zyzs[0].zyz].zylb;
            const zyData=[];
            const zylb=res2.data.obj.zyz[zyzs[0].zyz].zylb;
            const zyLen=res2.data.obj.zyz[zyzs[0].zyz].zylb.length;
            for(let i=0;i<zyLen;i++){
                const subs=zylb[i].subs;
                for(let j=0;j<subs.length;j++){
                    console.log(subs[j]);
                    zyData.push(subs[j]);
                }
            }
            console.log(zyData);
            this.zyData=zyData;
            // this.zyzRowspan=4;
            // this.zyzTotals.jnjhs=res2.data.obj.zyzTotals[0].sub[0].jnjhs;
            // this.zyzTotals.sfsc=res2.data.obj.sfsc;
        }else if(res2.data.code===500){
            if(res2.data.message){
                this.$message({message:res2.data.message,type:"error"});
            }
        }else if(res2.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res2.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
        loading2.close();
        this.$forceUpdate();
    },
    // 关闭当前页面
    closeWin() {
                const userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
                    window.open('','_self').close();
                }else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
    },
    // 跳转到专业组详情
    toZyzDetail(gbdm,yxxh,yxmc,zyz,pc){
        const url=this.$router.resolve({
            path:"/zyzDetail",
            query:{
                gbdm,
                yxxh,
                yxmc,
                zyz,
                pc
            }
        });
        window.open(url.href,"_blank");
    },
    // 去除HTML标签，提取其中的文字
    highlight(item){
        return item.replace(/<[^>]+>/g, '');
    },
    // 获取招生章程
    async getZszc(){
        const res=await this.$http.getHttp("/yxcx/getZszc?gbdm="+this.detail.gbdm);
        if(res.data.code===200){
            if(res.data.obj){
                this.detail.zszc=res.data.obj;
            }else {
                this.detail.zszc="暂无招生章程!";
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginout",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 查看简介和收起简介
    watchAndStop(){
        const maxwidth=this.maxwidth;
        const txt=this.detail.jj;
        if(txt.length-7<=maxwidth){
            this.detail.jj=this.detail.lsjj;
            this.detailTxt="<< 收起详情";
        }else{
            this.detail.jj=txt.substring(0,maxwidth)+".......";
            this.detailTxt="查看详情 >>";
        }
    },
    // 获取已经收藏的院校专业组个数
    async getYxzyz(){
        const { pc } = this.$route.query;
        const res=await this.$http.postHttp("/yhgz/selectYhgzYx",{
            fs:this.userInfo.gkfs,
            kl:this.userInfo.kl,
            xm:this.userInfo.xm,
            yhid:this.userInfo.yhid,
            pc
        });
        if(res.data.code===200){
            this.scZyzSize=res.data.obj.length;
            this.xh=res.data.obj.length;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 跳转到专业详情页面
    toZyDetail(zydm){
        const url=this.$router.resolve({
            path:"/majorDetail",
            query:{zydm}
        });
        window.open(url.href,"_blank");
    },
    // 跨行
    objectSpanMethod({row, column, rowIndex, columnIndex }){
        if(columnIndex===0||columnIndex===1||columnIndex===11){
            if(rowIndex%4===0){
                return{
                    rowspan:4,
                    colspan:1
                };
            }else {
                return {
                    rowspan:0,
                    colspan:0
                };
            }
        }
    },
    getCellClass({row,column,columnIndex}){
        if(row%3===0&&columnIndex===7){
            return 'yuceTdx';
        }else{
            return '';
        }
    },
    // 用jQuery实现查看详情和收起详情
    // watchAndStop(){
    //     $('.inner .intro').each(function () {
    //                             var maxwidth = 300; // 设置最多显示的字数
    //                             var textTemp = $(this).text(); // 临时记录
    //                             if ($(this).text().length > maxwidth) {
    //                                 $(this).text($(this).text().substring(0, maxwidth) + ".......");
    //                                 $(".read-more a").text("查看详情 >>");
    //                             }
    //                             $(".read-more a").click(function () {
    //                                 var temp = $('.inner .intro');
    //                                 if (temp.text().length - 7 <= maxwidth) {
    //                                     temp.text(textTemp);
    //                                     $(this).text("<< 收起详情");
    //                                 } else {
    //                                     temp.text(temp.text().substring(0, maxwidth) + ".......");
    //                                     $(this).text("查看详情 >>");
    //                                 }
    //                             });
    //                         });
    // },
            // 加入收藏
    // 关注院校
    async addCollect(zyz,status){
        const { pc,yxxh } = this.$route.query;
        this.collectLoading=true;
        const res=await this.$http.postHttp("/yhgz/addGzYxZyz",{
            yhid:this.userInfo.yhid,
            yxxh,
            pc,
            kl:this.userInfo.sxkm,
            zyz,
            xm:this.userInfo.xm,
            fs:this.userInfo.gkfs+"",
            xh:"",
            status,
            gzsj:""
        });
        if(res.data.code===200){
            // await this.getYxzyz();
            const num=res.data.obj;
            if(res.data.message){
            const msg=res.data.message+'，共收藏 '+num+' 所院校专业组';
            this.$message({type:"success",message:msg,showClose:true});
            }else{
            const msg='操作成功'+'，共收藏 '+num+' 所院校专业组';
            this.$message({type:"success",message:msg,showClose:true});
        }
        }else if(res.data.code===500){
            this.$message({message:res.data.message,type:"error"});
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        const type=this.type;
        if(type==="1"){
            const list=this.zyzTotals.list;
            for(let i=0;i<list.length;i++){
                if(list[i].zyz===zyz){
                    const isSc=list[i].isSc;
                    if(isSc===0){
                        list[i].isSc=1;
                    }else{
                        list[i].isSc=0;
                    }
                }
            }
        }else if(type==="2"){
            const isSc=this.isSc;
            if(isSc===0){
                this.isSc=1;
            }else{
                this.isSc=0;
            }
        }
        this.$forceUpdate();
        this.collectLoading=false;
        // const type=this.type;
        // if(type==="1"){
        //     await this.getSchoolDeatil();
        // }else if(type==="2"){
        //     await this.getZyzDeatil();
        // }
        // this.$forceUpdate();
    }
},
computed:{
...mapState(["userInfo"])
},
async mounted(){
    const type=this.type;
        if(type==="1"){
            await this.getSchoolDeatil();
        }else if(type==="2"){
            await this.getZyzDeatil();
        }
        await this.getZszc();
        this.detail.jj=this.highlight(this.detail.jj);
        this.detail.lsjj=this.highlight(this.detail.jj);
        const maxwidth=this.maxwidth;
        let txt=this.detail.jj;
        if(txt.length>maxwidth){
            txt=txt.substring(0,maxwidth)+".......";
        }
        this.detail.jj=txt;
}
};
</script>
<style lang="less" scoped>
/deep/ .el-table .yuceTdx{
    color: #ED4A09 !important;
}
.school-detail-wraper{
    min-width:1100px;
    margin:0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .detail-top{
        display: flex;
        align-items: center;
        width: 1100px;
        margin: 0 auto;
        padding: 20px 0;
        background-color: #fff;
        .top-logo{
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            margin: 0 30px;
        }
        .top-mid{
            width: 80%;
            box-sizing: border-box;
            color: #666;
        }
        .mid-school-name{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
        }
        .mid-school-nature{
            display: flex;
            font-size: 14px;
            .nature-item{
                flex: 1;
                .school-link{
                    cursor: pointer;
                }
            }
        }
    }
    .detail-main{
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 20px 30px;
        background-color: #fff;
    }
}
.schoolIntro_con2 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    // margin: 0 20px 50px 190px;
    min-height: 500px;
    margin-left:15px;
    // padding-top: 130px;
}
.catTitle2 {
    height: 35px;
    border-left: 5px solid #5287c6;
    padding-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    h2{
        font-size: 26px;
        line-height: 35px;
        font-weight: normal;
        color: #333;
    }
}
.info-data{
    width: 100%;
    color: #666;
    th{
        border: 1px solid #09f;
        padding: 1px 0;
        text-align: center;
        background-color: #e4f5ff;
    }
    td{
        padding: 1px 5px;
        border: 1px solid #09f;
        text-align: center;
    }
}
.schoolIntro_con {
    // margin: 30px 0 30px 180px;
    min-height: 500px;
    width: 510px;
    float: left;
    display: inline;
    .intro{
        text-align: justify;
        word-wrap: break-word;
        margin-bottom: 15px;
    }
    .txt{
        text-align: justify;
        word-wrap: break-word;
        margin-bottom: 35px;
    }
    a{
        color: #2186de;
    }
}
.baseInfo{
    border-top: 2px solid #efaf49;
    // background: url(school.css);
    line-height: 33px;
    margin-bottom: 20px;
    height: 170px;
    li{
        height: 33px;
        vertical-align: top;
    }
    .t{
        float: left;
      }
      .c{
        float: right;
        text-align: right;
        color: #999;
      }
}
.clearfix {
    *zoom: 1;
}
.baseInfo_left {
    float: left;
    width: 47%;
}
.baseInfo_right {
    float: right;
    width: 47%;
}
.catTitle {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    margin-bottom: 10px;
    h2{
        font-size: 26px;
        line-height: 35px;
        font-weight: normal;
        color: #efaf49;
        text-align: left;
    }
}
.schoolIntro_side {
    float: right;
    width: 260px;
    margin-top: 30px;
    .infos{
        border: 1px solid #E5E5E5;
        border-top: 0;
        ul{
            padding: 5px 12px 12px 12px;
        }
        li{
            padding: 10px 0 10px 75px;
            border-bottom: 1px dashed #DDD;
            line-height: 18px;
            word-wrap: break-word;
            overflow: hidden;
        }
        label{
            float: left;
            margin-left: -75px;
        }
        .website{
            height: 30px;
            margin-left: 15px;
            margin-bottom: 10px;
            a{
                line-height: 28px;
                float: left;
                border: 1px solid #D6D6D6;
                border-radius: 5px;
                text-align: center;
            }
            .a1{
                width: 90px;
                margin-right: 13px;
            }
            .a2{
                width: 120px;
            }
        }
    }
}
.schoolIntro_sideBd {
    width: 260px;
}
.schoolPic_slide {
    width: 260px;
    height: 240px;
    overflow: hidden;
    position: relative;
    ul{
        width: 260px;
        height: 240px;
        overflow: hidden;
    }
    li{
        float: left;
    }
    img{
        width: 260px;
        height: 240px;
    }
}
.collectBtn{
    width: 36px;
    padding: 3px;
    margin: 0 auto;
    font-size: 13px;
    color: #fff;
    border: 1px solid #f17070;
    border-radius: 3px;
    background: #f17070;
    cursor: pointer;
}
.schoolIntro_con22 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    margin-left:15px;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
}
h3{
    font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
}
.majorCon1 {
    margin-bottom: 25px;
    .list{
        margin-bottom: 15px;
    // background: url(school.css);
    li{
        // background: url(school.css) no-repeat 28px 20px;
        text-indent: 18px;
        height: 45px;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        float: left;
        width: 235px;
        padding-left: 15px;
        list-style: square;
    }
    }
}
}
.schoolIntro_con23 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    margin-left:15px;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
    h3{
        font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
    }
}
.txt{
    margin-bottom: 20px;
}
}
.yuceTdx{
    color: #ED4A09;
}
.yx_zy_group {
    box-sizing: border-box;
    border: 1px solid #eaeaea;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}
.group_title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
    color: #fefefe;
    background: rgb(38, 190, 228);
    .collect{
        padding: 3px 10px;
        font-size: 14px;
        color: #fff;
        border: 1px solid #f17070;
        border-radius: 3px;
        background: #f17070;
        cursor: pointer;
    }
}
.group_content {
    padding: 10px 0 10px;
    border-bottom: 1px solid #eaeaea;
    .zy_name {
    width: 96%;
    padding-left: 15px;
    font-size: 16px;
    color: #303133;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .index{
        display: inline-block;
        padding: 0 6px;
        background-color: #5bc3ef33;
    }
    .tip{
        font-size: 14px;
        color: #606266;
    }
}
.zy_attrs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 10px 0 0;
    .attr-item:first-child {
    margin-left: 40px;
    }
    .attr-item {
    margin-right: 20px;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 12px;
    color: #ff6900;
    background-color: #ff690021;
}
}
}
</style>
