<template>
    <div>
        <!-- height="660" -->
        <div class="yfd-title">一分段数据列表展示</div>
        <div id="yfd-data">
            <el-table :data="yfdList" border
            :header-cell-style="headerCellStyle" height="400"
            :cell-style="cellStyle">
                <el-table-column show-overflow-tooltip label="首选科目" width="60" prop="kl" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="scope.row.kl===1">历史</span>
                        <span v-if="scope.row.kl===2">物理</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="档分" width="60" prop="df" :resizable="false">
                </el-table-column>
                <el-table-column show-overflow-tooltip :label="year1" width="320" :resizable="false">
                <el-table-column show-overflow-tooltip label="含全国性加分" width="160" :resizable="false">
                <el-table-column show-overflow-tooltip label="本段人数" width="80" prop="bdrs1" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.bdrs1">--</span>
                        <span v-if="scope.row.bdrs1">{{scope.row.bdrs1}}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="累计人数" width="80" prop="ljrs1" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.ljrs1">--</span>
                        <span v-if="scope.row.ljrs1">{{scope.row.ljrs1}}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="含全国性和地方加分" width="160" :resizable="false">
                    <el-table-column label="本段人数" width="80" prop="bdrs2">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.bdrs2">--</span>
                        <span v-if="scope.row.bdrs2">{{scope.row.bdrs2}}</span>
                    </template>
                </el-table-column>
                    <el-table-column show-overflow-tooltip label="累计人数" width="80" prop="ljrs2" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.ljrs2">--</span>
                        <span v-if="scope.row.ljrs2">{{scope.row.ljrs2}}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip :label="year2" width="320" :resizable="false">
                <el-table-column show-overflow-tooltip label="含全国性加分" width="160" :resizable="false">
                <el-table-column show-overflow-tooltip label="本段人数" width="80" prop="bdrs3" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.bdrs3">--</span>
                        <span v-if="scope.row.bdrs3">{{scope.row.bdrs3}}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="累计人数" width="80" prop="ljrs3" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.ljrs3">--</span>
                        <span v-if="scope.row.ljrs3">{{scope.row.ljrs3}}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="含全国性和地方加分" width="160" :resizable="false">
                    <el-table-column label="本段人数" width="80" prop="bdrs4" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.bdrs4">--</span>
                        <span v-if="scope.row.bdrs4">{{scope.row.bdrs4}}</span>
                    </template></el-table-column>
                    <el-table-column label="累计人数" width="80" prop="ljrs4" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.ljrs4">--</span>
                        <span v-if="scope.row.ljrs4">{{scope.row.ljrs4}}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip :label="year3" width="320" :resizable="false">
                <el-table-column show-overflow-tooltip label="含全国性加分" width="160" :resizable="false">
                <el-table-column show-overflow-tooltip label="本段人数" width="80" prop="bdrs5" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.bdrs5">--</span>
                        <span v-if="scope.row.bdrs5">{{scope.row.bdrs5}}</span>
                    </template></el-table-column>
                <el-table-column show-overflow-tooltip label="累计人数" width="80" prop="ljrs5" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.ljrs5">--</span>
                        <span v-if="scope.row.ljrs5">{{scope.row.ljrs5}}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="含全国性和地方加分" width="160" :resizable="false">
                    <el-table-column show-overflow-tooltip label="本段人数" width="80" prop="bdrs6" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.bdrs6">--</span>
                        <span v-if="scope.row.bdrs6">{{scope.row.bdrs6}}</span>
                    </template></el-table-column>
                    <el-table-column show-overflow-tooltip label="累计人数" width="80" prop="ljrs6" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.ljrs6">--</span>
                        <span v-if="scope.row.ljrs6">{{scope.row.ljrs6}}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                </el-table-column>
            </el-table>
            <!-- <el-table>
                <el-table-column label="科目" porp="kl">
                    <template slot-scope="scope">
                        <span v-if="scope.row.kl===1">历史</span>
                        <span v-if="scope.row.kl===1">物理</span>
                    </template>
                </el-table-column>
                <el-table-column label="年度" prop="nd"></el-table-column>
                <el-table-column label="档分" prop="df"></el-table-column>
                <el-table-column label="含全国性加分">
                    <el-table-column label="本段人数" prop="bdrs1"></el-table-column>
                    <el-table-column label="累计人数" prop="ljrs1"></el-table-column>
                </el-table-column>
                <el-table-column label="含全国性和地方加分">
                    <el-table-column label="本段人数" prop="bdrs2"></el-table-column>
                    <el-table-column label="累计人数" prop="ljrs2"></el-table-column>
                </el-table-column>
            </el-table> -->
            <!-- <scroll-table-vue :year1="year1"
            :year2="year2" :year3="year3"></scroll-table-vue> -->
            <div class="yfd-page">
                <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="planParam.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="planParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="yfdTotal">
        </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
// import scrollTableVue from '../../../components/scrollTable.vue';
export default {
data(){
    return{
        year1:"",
        year2:"",
        year3:"",
        planParam:{
            pageNum:1,
            pageSize:10
        },
        yfdTotal:0,
        yfdList:[]
    };
},
methods:{
    async getYfd(){
        this.loadData();
    },
   async loadData(pageNum=1,pageSize=10){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("yfd-data"),text:"正在加载中"});
        const res=await this.$http.getHttp("/lnsj/yfd?pageNum="+pageNum+"&&pageSize="+pageSize+"&kl="+this.userInfo.sxkm);
        if(res.data.code===200){
            this.yfdList=res.data.obj.ipage.records;
            this.yfdTotal=res.data.obj.ipage.total;
            if(res.data.obj.nds.length>0){
                this.year1=res.data.obj.nds[0]+"年";
                this.year2=res.data.obj.nds[1]+"年";
                this.year3=res.data.obj.nds[2]+"年";
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    async handleSizeChange(pageSize){
        await this.loadData(1,pageSize);
    },
    async handleCurrentChange(pageNum){
        await this.loadData(pageNum,10);
    },
    // 渲染表格每列的边框
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex===2||columnIndex===6||columnIndex===10){
            return{border:'1px solid #0099FF','border-left':'3px solid #0099FF','text-align':'center'};
        }else if(columnIndex===13){
            return{border:'1px solid #0099FF','border-right':'3px solid #0099FF','text-align':'center'};
        }else{
            return{border:'1px solid #0099FF','text-align':'center'};
        }
    },
    // 渲染表格表头每列的边框
    headerCellStyle({row, column, rowIndex, columnIndex}){
        if(((columnIndex===2||columnIndex===3)&&rowIndex===0)||
        ((columnIndex===0||columnIndex===2||columnIndex===4)&&rowIndex===1)||
        ((columnIndex===0||columnIndex===4||columnIndex===8)&&rowIndex===2)){
            return{'background-color':'#B0E2FF',color: '#3B3B3B',border:'1px solid #0099FF','border-left':'3px solid #0099FF','text-align':'center'};
        }else if((columnIndex===4&&rowIndex===0)){
            return{'background-color':'#B0E2FF',color: '#3B3B3B',border:'1px solid #0099FF','border-right':'3px solid #0099FF','border-left':'3px solid #0099FF','text-align':'center'};
        }else if((columnIndex===5&&rowIndex===1)||
        (columnIndex===11&&rowIndex===2)){
            return{'background-color':'#B0E2FF',color: '#3B3B3B',border:'1px solid #0099FF','border-right':'3px solid #0099FF','text-align':'center'};
        }else{
            return{'background-color':'#B0E2FF',color: '#3B3B3B',border:'1px solid #0099FF','text-align':'center'};
        }
    }
},
computed:{
    ...mapState(["userInfo"])
},
components:{
},
async mounted(){
await this.getYfd();
}
};
</script>
<style lang="less" scoped>
.yfd-title{
    width:1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:18px;
    font-weight: bold;
    margin:10px 0;
}
.yfd-page{
    width:600px;
    margin: 20px auto;
}
.cust-col{
    border-right: 10px solid #0099FF;
}
</style>
