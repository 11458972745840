import Vue from 'vue';
import {DatePicker,TimePicker,Collapse,CollapseItem,Autocomplete,Tag,Dialog,Popconfirm,Popover,Progress,Transfer,Tooltip,Pagination,Table,TableColumn,Tabs,TabPane,RadioGroup,Radio,CheckboxGroup,Checkbox,Cascader,Breadcrumb,BreadcrumbItem,Image,Button,Input,Select,Option,Message,Link,Form,FormItem,Icon,Carousel,CarouselItem} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$message=Message;
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Link);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Cascader);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Table);
Vue.use(Pagination);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Transfer);
Vue.use(Progress);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Autocomplete);
Vue.use(TimePicker);
Vue.use(DatePicker);
