<template>
    <div>
        <div>
        <div class="main_box px1005 box950" style="padding-left:30px;">
            <nav>
                <a class="maincolor" href="/">首页</a> <span class="c-666 en">&gt;</span>在线购卡
            </nav>
        </div>
        <div class="buyCard-mod1">
            <div class="g-wrapper">
                <div class="item album">
                    <div class="list cf" id="J-buyCard-mod1-slide">
                    <img alt="金榜通卡样式" src="../assets/images/jdt-card.png">
                    </div>
                </div>
                <div class="item buy">
                    <h1>金榜志愿通系统（包含下载和志愿评估功能）</h1>
                    <div class="buyCard-mod1-text01">
                        <h2>
                            志愿是海，工具是舟<br> 金榜志愿通，高考定成功！
                        </h2>
                    </div>
                    <dl class="province cf">
                        <dt>适用省份</dt>
                        <dd>
                            <span>湖南</span><span>广东</span><span>四川&nbsp;&nbsp;&nbsp;</span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;适用人群</span>
                            <span> 老师，家长，高中学生</span>
                        </dd>
                    </dl>
                    <!-- <dl class="text cf">
                        <dt>适用人群</dt>
                        <dd> 老师，家长，高中学生</br></dd>
                    </dl> -->
                    <div class="plus">
                        <ul>
                            <li>2023年志愿推荐成功率<strong>99.7%</strong></li>
                            <li>2022年志愿推荐成功率<strong>99.5%</strong></li>
                            <li>2021年志愿推荐成功率<strong>99.5%</strong></li>
                            <li>2020年志愿推荐成功率<strong>99.3%</strong></li>
                            <li>2019年志愿推荐成功率<strong>99.2%</strong></li>
                            <li>2018年志愿推荐成功率<strong>99.1%</strong></li>
                        </ul>
                    </div>
                    <dl class="price cf">
                        <dt style="width: 105px;">全国统一零售价</dt>
                        <dd style="position:relative;">
                            <!-- <div style="width:80px;position:absolute;border-bottom:1px solid red;top:14px;"></div> -->
                            <em><dfn>¥</dfn>668.00</em> 元/张
                        </dd>
                        <!-- <dt style="width: 100px;">金卡统一零售价</dt>
                        <dd>
                            <em><dfn>¥</dfn>668.00</em> 元/张&nbsp;&nbsp;
                        </dd> -->
                    </dl>
                    <div class="action cf">
                        <div class="buy2" >
                            <a class="buy_a" data_cpid="1" title="立即购买" @click="openBuy('1')">在线购买</a>
                        </div>
                        <!--  <div class="buy2" >
                            <a href="/pay/index?cpid=2" class="buy_a" data_cpid="2" title="立即购买">美术艺考生购卡</a>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="g-wrapper">
                <div class="item album">
                    <div class="list cf" id="J-buyCard-mod1-slide">
                    <img alt="金榜通卡样式" src="../assets/images/jbtyk03.png" style="width:248px;margin-right: 4px;">
                    <img alt="金榜通卡样式" src="../assets/images/jbtyk04.png" style="width:248px;">
                    </div>
                </div>
                <div class="item buy">
                    <h1>金榜志愿通系统（<span style="color:red;font-weight: bold;">不</span>包含下载和志愿评估功能）</h1>
                    <div class="buyCard-mod1-text01">
                        <h2>
                            志愿是海，工具是舟<br> 金榜志愿通，高考定成功！
                        </h2>
                    </div>
                    <dl class="province cf">
                        <dt>适用省份</dt>
                        <dd>
                            <span>湖南</span><span>广东</span><span>四川&nbsp;&nbsp;&nbsp;</span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;适用人群</span>
                            <span> 老师，家长，高中学生</span>
                        </dd>
                    </dl>
                    <div class="plus">
                        <ul>
                            <li>2023年志愿推荐成功率<strong>99.7%</strong></li>
                            <li>2022年志愿推荐成功率<strong>99.5%</strong></li>
                            <li>2021年志愿推荐成功率<strong>99.5%</strong></li>
                            <li>2020年志愿推荐成功率<strong>99.3%</strong></li>
                            <li>2019年志愿推荐成功率<strong>99.2%</strong></li>
                            <li>2018年志愿推荐成功率<strong>99.1%</strong></li>
                        </ul>
                    </div>
                    <dl class="price cf">
                        <dt style="width: 105px;">全国统一零售价</dt>
                        <dd style="position:relative;">
                            <div style="width:80px;position:absolute;border-bottom:1px solid red;top:14px;"></div>
                            <em><dfn>¥</dfn>668.00</em> 元/张
                        </dd>
                        <dt style="width: 100px;">银卡统一零售价</dt>
                        <dd>
                            <em><dfn>¥</dfn>368.00</em> 元/张&nbsp;&nbsp;
                        </dd>
                    </dl>
                    <div class="action cf">
                        <div class="buy2" >
                            <a class="buy_a" data_cpid="3" title="立即购买" @click="openBuy('3')">在线购买</a>
                        </div>
                    </div>
                </div>
            </div> -->
            <hr class="line">
            <div class="g-wrapper">
                <div class="item album">
                    <div class="list cf" id="J-buyCard-mod1-slide">
                        <img alt="金榜通卡样式" src="../assets/images/consult.jpg" width="100%" height="100%">
                    </div>
                </div>
                <div class="item buy">
                    <h1><span style="color:#e43838">一对一个性化咨询服务</span>（您今天和我们的专家对接成功，您和你的小孩就对接了一个人脉圈!）</h1>
                    <div class="buyCard-mod1-text02">
                        <h2>
                            <span>
                                        ★如果您工作忙没有时间研究高考招生政策，填报志愿无从下手；<br>
                                        ★如果您已经草拟了志愿填报方案，但又举棋不定，患得患失；<br>
                                        ★如果您做事精益求精，已经对志愿有了相当了解，但需要专家提供意见，沟通看法；<br>
                                        ★如果您是边缘考生，特别珍惜上线的机会；<br>
                                        ★如果您想了解大学、专业、就业等个性化的信息；<br>
                                ……<br>
                                <span style="color:#555">如果您属于这些现象人群，建议来找我们金榜志愿通团队的专家！您选或者不选，我们都在这里！</span>
                            </span>
                        </h2>
                    </div>
                    <dl class="province cf">
                        <dt>适用省份</dt>
                        <dd>
                            <span>湖南</span><span>广东</span><span>四川</span>
                        </dd>
                    </dl>
                    <dl class="text cf">
                        <dt>适用考生</dt>
                        <dd>普通文化类考生</dd>
                    </dl>
                    <dl class="price cf">
                        <dd style="padding: 5px;color:#fff;background-color: #2186DE;border-radius: 3px;">
                            具体详情，请咨询陈老师。联系电话：13787139119
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="buyCard-mod2">
            <div class="g-wrapper">
                <!-- <div class="navlist" id="J-buyCard-mod2-nav">
                    <div class="inner">
                        <ul>
                            <li data-id="#function" class="selected"><a href="javascript:;">功能介绍</a></li>
                            <li data-id="#notice"><a href="javascript:;">购买须知</a></li>
                            <li data-id="#comment1"><a href="javascript:;">公益讲座</a></li>
                            <li data-id="#comment2"><a href="javascript:;">用户评价</a></li>
                            <li data-id="#use"><a href="javascript:;">使用指南</a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="con">
                    <!-- 功能介绍 -->
                    <!-- <div class="intro function" id="function">
                        <h2>金榜志愿通系统功能介绍</h2>
                    </div>
                    <div class="coreFunction" id="J-buyCard-mod2-function">
                            <div class="item f00"></div>
                            <img src="../assets/images/8dys-01.png" width="964" height="279"> <img
                                src="../assets/images/8dys-02.png" width="964" height="468"><img
                                src="../assets/images/8dys-03.png" width="964" height="442">
                    </div> -->
                    <!-- 购买需知 -->
                    <!-- <div class="intro notice" id="notice">
                        <h2>购买须知</h2>
                        <img src="../assets/images/gmxz-01.png" width="980" height="368">
                        <div class="knowCon">
                            <div class="caluse">
                                <em class="f00"> 联系我们 </em>
                                <p>
                                    购卡、咨询热线：4000-190-550、0731-89722148<br>
                                    QQ：44394435<br> 邮箱：44394435@qq.com<br>
                                </p>
                            </div>
                            <div class="area">
                                <p class="f00">此卡所适用的省份</p>
                                <ul class="cf">
                                    <li>江苏</li><li>浙江</li><li>安徽</li>
                                    <li>河南</li><li>河北</li>
                                    <li style="color: #06F;">湖南</li>
                                    <li>湖北</li>
                                    <li>江西</li><li>广东</li><li>广西</li>
                                    <li>山东</li><li>山西</li><li>陕西</li><li>云南</li><li>贵州</li><li style="color: #06F;">四川</li><li>重庆</li>
                                    <li>辽宁</li><li>吉林</li><li>福建</li><li>甘肃</li><li>新疆</li><li>黑龙江</li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <!-- 公益讲座-->
                    <!--<hr class="line2">-->
                    <!--<div class="intro comment" id="comment1">
                        <h2>公益讲座</h2>
                        <div style="width: 985px;">
                            <div class="scroll">
                                <div class="scroll_left" id="LeftArr"></div>
                                <div class="pic" id="scrollPic" style="width: 930px;">
                                    <ul style="width: 930px;">
                                        <li style="width: 930px;">
                                            <a href="http://www.zy1dt.com/comment" target="_blank" title="公益讲座">
                                            <img src="../assets/images/pinjia01.jpg" width="910" height="672"></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="scroll_right" id="RightArr"></div>
                            </div>
                        </div>
                    </div>-->
                    <!--<hr class="line2">-->
                    <!--<div class="intro comment" id="comment2">
                        <h2>用户评论</h2>
                        <div style="width: 985px;">
                            <div class="scroll">
                                <div class="pic" id="scrollPic">
                                    <ul>
                                        <li>
                                            <a href="http://www.zy1dt.com/comment" target="_blank" title="用户评价">
                                                <img src="../assets/images/comment01.png" width="980" height="672">
                                                <img src="../assets/images/pingjia.jpg" width="980" height="672">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <hr class="line2">
                    <!-- 使用指南 -->
                    <div class="intro use" id="use">
                        <!-- <h2>使用指南</h2> -->
                            <div class="syzn-pic04" style="width:480px;margin:0 auto;">
                                <div style="margin-right:20px;">
                                    <div style="margin-left:20px;font-size:16px;color:#EE4000;font-weight: bold;">GK金榜志愿通</div>
                                    <img alt="订阅号" src="../assets/images/weixin.jpg" width="140" height="140"/>
                                    <div style="margin-left:32px;font-size:16px;color:#EE4000;font-weight: bold;">微信公众号</div>
                                </div>
                                <!-- <div style="font-size:16px">扫码关注微信公众号“GK金榜志愿通”，在微信公众号里查询最近日期的系统使用手册。</div> -->
                                <div style="font-size:30px;font-weight:bold;color:black;">欢迎扫一扫</div>
                                <div style="margin-left:20px;">
                                    <div style="margin-left:20px;font-size:16px;color:#EE4000;font-weight: bold;">GK金榜志愿通</div>
                                    <img alt="小程序" src="../assets/images/xiaochengxuma.jpg" width="140" height="140"/>
                                    <div style="margin-left:30px;font-size:16px;color:#EE4000;font-weight: bold;">微信小程序</div>
                                </div>
                            </div>
                            <!--
                            <div class="step syzn-pic01">
                                <div class="text">
                                    <div class="title">在线支付购买“金榜志愿通”卡，获取卡号和密码。</div>
                                    <p>
                                        考生或其家长通过本网站在线支付购买“金榜志愿通”卡，购买成功后，系统会通过短信方式将卡号和密码发送到您购卡时提供的联系手机上。请您确保手机畅通，以便及时接收本网站发送的短信。
                                    </p>
                                </div>
                            </div>
                            <div class="step syzn-pic02">
                                <div class="text2">
                                    <div class="title">注册成为“金榜志愿通系统”的正式用户</div>
                                    <p>
                                        考生或其家长访问“金榜志愿通系统”（http://www.zy1dt.com/）。在系统首页，填写考生个人信息，以及购买的“金榜志愿通”卡号和密码信息，注册成为正式用户。
                                    </p>
                                </div>
                            </div>
                            <div class="step syzn-pic03">
                                <div class="text3">
                                    <div class="title">登录“金榜志愿通系统”，享用高考志愿填报信息服务</div>
                                    <p>
                                        考生或其家长凭注册的“金榜志愿通系统”用户名和密码，登录“金榜志愿通系统”，即可使用“金榜志愿通系统”提供的全部功能，功能的具体操作使用见“使用流程”页面。
                                    </p>
                                </div>
                            </div>
                            -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog  title="金榜志愿通-文化卡" :visible.sync="dialogBuyVisible" width="30%"
        @close="closeDialog">
        <el-form>
            <el-form-item label="商品类型">
                <el-select v-model="buyInfo.type" style="width:150px;" @change="changeType">
                    <!-- <el-option value="1" label="金榜通志愿卡"></el-option>
                    <el-option value="3" label="金榜通志愿卡"></el-option> -->
                    <el-option v-for="(item,index) in cpmcs" :key="index" :value="item.value"
                    :label="item.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="适用省份">
                <el-select v-model="buyInfo.sssf" style="width:150px;">
                    <el-option value="43" label="湖南"></el-option>
                    <el-option value="44" label="广东"></el-option>
                    <el-option value="51" label="四川"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="城市">
                <el-select v-model="buyInfo.city" style="width:140px;"
                @change="changeShi">
                <template v-if="citys.length>0">
                <el-option v-for="(item,index) in citys"
                :key="index" :label="item.name" :value="item.value"></el-option>
                </template>
                </el-select>
            </el-form-item>
            <el-form-item label="地区">
                <el-select v-model="buyInfo.area" style="width:140px;"
                @change="changeQu">
                <template v-if="areas.length>0">
                <el-option v-for="(item,index) in areas"
                :key="index" :label="item.name" :value="item.value"></el-option>
                </template>
                </el-select>
            </el-form-item>
            <el-form-item label="学校">
                <el-select v-model="buyInfo.school" style="width:140px;">
                <template v-if="schools.length>0">
                <el-option v-for="(item,index) in schools"
                :key="index" :label="item.name" :value="item.value"></el-option>
                </template>
                </el-select>
            </el-form-item> -->
            <el-form-item label="购卡单价">
                <span class="buy-price">￥{{buyInfo.dj}}</span>
            </el-form-item>
            <el-form-item label="购卡数量">
                <div>
                <el-button size="small" type="primary" style="height:42px;" @click="reduceNum">-</el-button>
                <el-input v-model="buyInfo.num" style="width:70px;" @change="changeNum"></el-input>
                <el-button size="small" type="primary" style="height:42px;" @click="addNum">+</el-button>
                </div>
            </el-form-item>
            <el-form-item label="支付金额">
                <span class="pay-price">￥{{buyInfo.payPrice}}</span>
            </el-form-item>
            <el-form-item label="支付方式">
                <el-radio-group v-model="buyInfo.payType">
                    <el-radio :label="1">支付宝</el-radio>
                    <el-radio :label="2">微信</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="手机号码">
                <el-input style="width:120px;" v-model="buyInfo.sjhm"></el-input>
                <el-button type="primary"
                style="background-color: #F19485;border: 1px solid white;margin-left:20px;"
                @click="sendCode" :disabled="yzmDisabled">{{yzmTxt}}</el-button>
            </el-form-item>
            <el-form-item label="验证码">
                <el-input v-model="buyInfo.yzm" style="width:120px;margin-left:14px;"></el-input>
            </el-form-item>
            <el-form-item>
                <div style="margin: 0 auto;width:100px;">
                    <el-button type="primary" @click="payCard">立即支付</el-button>
                </div>
            </el-form-item>
            <div class="note">
                <span style="color: red;">特别提示：</span>
                <p style="margin:10px 0;">1、购卡成功后，请到手机上查看信息。并立即注册</p>
                <p style="margin:10px 0;">2、每张卡保障1位考生使用一个高考季，有效期内无使用次数和时间限制;本卡不记名、不挂一经出售，概不退换!如有问题，可与我们的 客服联系4000-190-550</p>
            </div>
        </el-form>
        </el-dialog>
        <el-dialog
        :title="innerTitle"
        width="30%"
        :visible.sync="innerdialogVisible"
        @close="closeDialog"
      >
        <div class="weixin-panel">
          <div id="qrcode" class="qrcode" ref="qrCodeDiv"></div>
          <div class="qrcode-time">
            <i style="color:#ff7e00">{{ qrcodeTime }}s </i>后二维码过期
          </div>
        </div>
            </el-dialog>
    </div>
</template>
<script>
import QRCode from "qrcodejs2"; // 引入qrcode
import '../assets/css/buyCard.css';
import {mapState} from 'vuex';
export default {
data(){
    return {
        dialogBuyVisible:false,
        buyInfo:{
            type:"1",
            sssf:"43",
            num:"1",
            payPrice:"688",
            payType:1,
            sjhm:"",
            yzm:"",
            cpmc:"",
            dj:"",
            city:'',
            area:'',
            zxmc:'',
            school:'',
            wxPrice:''
        },
        cpmcs:[],
        citys:[],
        areas:[],
        schools:[],
        codeId:"",
        ddTimer:null,
        yzmTxt:"发送验证码",
        yzmDisabled:false,
        qrcodeTime:0,
        innerTitle:"",
        zfbTime:0,
        zfbDdTimer:null,
        intervalQrcodeTime:null,
        innerdialogVisible:false
    };
},
methods:{
    // 打开购买弹窗
    async openBuy(cpid){
        // await this.getCpxx(cpid);
        await this.getAllCpxx(cpid);
        this.dialogBuyVisible=true;
    },
    async getCitys(){
        const res=await this.$http.getHttp("/xzqh/shixian?dqbm=43");
    if(res.data.code===200){
      const arr=res.data.obj;
      const citys=[];
      for(let i=0;i<arr.length;i++){
        if(arr[i].dqbm){
          citys.push({name:arr[i].dqmc,value:arr[i].dqbm});
        }
      }
      this.citys=citys;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
    },
      // 改变城市
  async changeShi(cityId){
    const res=await this.$http.getHttp("/xzqh/shixian?dqbm="+cityId);
    if(res.data.code===200){
      const arr=res.data.obj;
      const areas=[];
      for(let i=0;i<arr.length;i++){
        if(arr[i].dqbm){
          areas.push({name:arr[i].dqmc,value:arr[i].dqbm});
        }
      }
      this.areas=areas;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  },
  // 改变地区
  async changeQu(dqId){
    const res=await this.$http.getHttp("/zxml/getShools?dqbm="+dqId);
    if(res.data.code===200){
      const arr=res.data.obj;
      const schools=[];
      for(let i=0;i<arr.length;i++){
        if(arr[i].szdq){
          schools.push({name:arr[i].zxmc,value:arr[i].zxxh});
        }
      }
      this.schools=schools;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  },
    // 减数量
    reduceNum(){
        let num=this.buyInfo.num;
        if(num==='1'){
            this.$message({message:"购卡数量不能少于1",type:"warning",showClose:true});
        }else{
           num=parseInt(num);
           if(isNaN(num)){
                this.$message({
                    message:'请输入数字',
                    type:'warning',
                    showClose:true
                });
                return;
            }
           num--;
           const price=num*parseFloat(this.buyInfo.dj);
           this.buyInfo.payPrice=price+"";
           this.buyInfo.num=num+"";
        }
    },
    // 增加数量
    addNum(){
        let num=this.buyInfo.num;
        if(num==='10'||num>10){
            this.$message({message:"一次性购买数量最多不超过10张",type:"warning",showClose:true});
            this.buyInfo.num='10';
        }else{
            num=parseInt(num);
            if(isNaN(num)){
                this.$message({
                    message:'请输入数字',
                    type:'warning',
                    showClose:true
                });
                return;
            }
            num++;
            const price=num*parseFloat(this.buyInfo.dj);
            this.buyInfo.payPrice=price+"";
            this.buyInfo.num=num+"";
        }
    },
    changeNum(n){
        const num=parseInt(n);
        if(isNaN(num)){
            this.$message({
                message:'请输入数字',
                type:'warning',
                showClose:true
            });
            return;
        }
        if(num>10){
            this.$message({message:"一次性购买数量最多不超过10张",type:"warning",showClose:true});
            this.buyInfo.num='10';
            this.buyInfo.payPrice=this.buyInfo.dj*10;
            return;
        }
        const dj=this.buyInfo.dj;
        this.buyInfo.payPrice=dj*num;
    },
    // 发送验证码
    async sendCode(){
        let s=60;
        this.yzmDisabled=true;
        var timer=null;
        const sjhm=this.buyInfo.sjhm;
        if(sjhm===''){
            this.$message({message:"请输入手机号",type:'warning',showClose:true});
            return;
        }
        const reg=/^1[3-8][0-9]{9}$/;
        if(sjhm.length<11||!(reg.test(sjhm))){
            this.$message({message:"请输入正确的手机号",type:"warning",showClose:true});
            return;
        }
        const res=await this.$http.getHttp("/msg/sendMsg?sjhm="+sjhm);
        if(res.data.code===200){
            this.codeId=res.data.obj;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$message({message: '登录超时，请重新登录',type:"error"});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        timer=setInterval(() => {
            s--;
            this.yzmTxt=s+"秒";
            if(s===0){
                clearInterval(timer);
                this.yzmDisabled=false;
                this.yzmTxt="发送验证码";
            }
        }, 1000);
    },
    // 获取购卡的信息
    async getCpxx(cpid='1'){
        const res=await this.$http.getHttp("/cpxx/getCpxxById?cpid="+cpid);
        if(res.data.code===200){
            // this.buyInfo.type=res.data.obj.id+"";
            this.buyInfo.payPrice=res.data.obj.xsjg;
            this.buyInfo.dj=res.data.obj.xsjg;
            this.buyInfo.cpmc=res.data.obj.cpmc;
            this.buyInfo.num='1';
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    async getAllCpxx(cpid){
        const arr=[...this.cpmcs];
        const res=await this.$http.getHttp("/cpxx/getCpxxById?cpid=1");
        if(res.data.code===200){
            if(cpid+''===res.data.obj.id+''){
                this.buyInfo.type=res.data.obj.id+"";
                this.buyInfo.payPrice=res.data.obj.xsjg;
                this.buyInfo.dj=res.data.obj.xsjg;
                this.buyInfo.cpmc=res.data.obj.cpmc;
            }
            const obj={};
            obj.name=res.data.obj.cpmc;
            obj.value=res.data.obj.id+'';
            const cpObj=this.cpmcs.find(item=>item.value===res.data.obj.id+'');
            if(!cpObj||!(cpObj.name)){
                arr.push(obj);
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        const res2=await this.$http.getHttp("/cpxx/getCpxxById?cpid=3");
        if(res2.data.code===200){
            // this.buyInfo.type=res.data.obj.id+"";
            // this.buyInfo.payPrice=res.data.obj.xsjg;
            // this.buyInfo.dj=res.data.obj.xsjg;
            // this.buyInfo.cpmc=res.data.obj.cpmc;
            if(cpid+''===res2.data.obj.id+''){
                this.buyInfo.type=res2.data.obj.id+"";
                this.buyInfo.payPrice=res2.data.obj.xsjg;
                this.buyInfo.dj=res2.data.obj.xsjg;
                this.buyInfo.cpmc=res2.data.obj.cpmc;
            }
            const obj={};
            obj.name=res2.data.obj.cpmc;
            obj.value=res2.data.obj.id+'';
            const cpObj=this.cpmcs.find(item=>item.value===res2.data.obj.id+'');
            if(!cpObj||!(cpObj.name)){
                arr.push(obj);
            }
        }else if(res2.data.code===500){
            if(res2.data.message){
                this.$message({message:res2.data.message,type:'error'});
            }
        }else if(res2.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res2.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res2.status===409||res2.status===410||res2.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.cpmcs=[...arr];
    },
    // 关闭二维码弹窗
    closeDialog(){
        this.dialogBuyVisible=false;
        this.innerdialogVisible=false;
        this.buyInfo.sjhm='';
        this.buyInfo.yzm='';
        this.buyInfo.num='1';
        clearInterval(this.intervalQrcodeTime);
        clearInterval(this.zfbDdTimer);
        localStorage.removeItem('aliPayCardDdh');
    },
    // 改变类型
    async changeType(type){
        await this.getCpxx(type);
    },
    // 购买
    async payCard(){
        // const codeId=this.codeId;
        const num=parseInt(this.buyInfo.num);
        if(isNaN(num)){
            this.$message({
                message:'请输入数字',
                type:'warning',
                showClose:true
            });
            return;
        }
        const obj={};
        obj.code=this.buyInfo.yzm;
        obj.cpid=this.buyInfo.type;
        obj.ddh="";
        obj.payType=this.buyInfo.payType;
        obj.sf=this.buyInfo.sssf;
        obj.sjhm=this.buyInfo.sjhm;
        obj.sum=this.buyInfo.num;
        obj.codeid=this.codeId;
        const sjhm=this.buyInfo.sjhm;
        const yzm=this.buyInfo.yzm;
        if(sjhm===''){
            this.$message({message:"请输入手机号",type:'warning',showClose:true});
            return;
        }
        const reg=/^1[3-8][0-9]{9}$/;
        if(sjhm.length<11||!(reg.test(sjhm))){
            this.$message({message:"请输入正确的手机号",type:"warning",showClose:true});
            return;
        }
        if(yzm.length<7){
            this.$message({message:"请输入正确的验证码",type:'warning',showClose:true});
            return;
        }
        const res=await this.$http.postHttp("/ddxx/ddSubmit",obj);
        if(res.data.code===200){
            if(this.buyInfo.payType===1){
            const ddh=res.data.obj.orderId;
            const res2=await this.$http.getHttp("/pay/aliPay?ddh="+ddh);
            const routerData = this.$router.resolve({
            path: "/payGateWay",
            query: { htmlData: res2.data }
            });
           localStorage.setItem('aliPayCardDdh',ddh);
           this.zfbTime=90;
           this.zfbDdTimer=setInterval(async ()=>{
            this.zfbTime--;
            const zfbDdh=localStorage.getItem('aliPayCardDdh');
            if(zfbDdh){
                const result=await this.$http.getHttp('/ddxx/getDdxxByDdh?ddh='+zfbDdh);
                if(result.data.code===200){
                        const zt=result.data.obj.ddzt;
                        if(zt===2){
                            this.$message({message:'支付成功',type:'success',showClose:true});
                            this.zfbTime=0;
                            clearInterval(this.zfbDdTimer);
                            localStorage.removeItem('aliPayCardDdh');
                            this.dialogBuyVisible=false;
                        }
                }else if(result.data.code===500){
                        if(result.data.message){
                            this.$message({message:result.data.message,type:'error'});
                        }
                }else if(result.data.code===0){
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                }
            }
            if(this.zfbTime===0){
                this.zfbTime=0;
                clearInterval(this.zfbDdTimer);
                localStorage.removeItem('aliPayCardDdh');
            }
           },1000);
        //   window.open("about:blank", "alipay");
        //   window.open(null, "alipay").location.href = routerData.href;
        window.open(routerData.href,"_blank");
            }else{
                const obj={};
                obj.orderId=res.data.obj.orderId;
                obj.orderAmount=this.buyInfo.payPrice;
                obj.productName=this.buyInfo.cpmc;
                obj.sjhm=this.buyInfo.sjhm;
                const res2=await this.$http.postHttp("/pay/wxPay",obj);
                if(res2.data.code===200){
                this.qrcodeTime=60;
                this.innerdialogVisible=true;
                this.$nextTick(()=>{
                    this.$refs.qrCodeDiv.innerHTML = "";
                    const qrcode = new QRCode("qrcode", {
                            width: 132,
                            height: 132,
                            text: res2.data.obj.urlCode, // 二维码地址
                            colorDark: "#000",
                            colorLight: "#fff"
                        });
                    console.log(qrcode);
                });
                this.intervalQrcodeTime=setInterval(async () => {
                    this.qrcodeTime--;
                    if (this.qrcodeTime === 0) {
                        clearInterval(this.intervalQrcodeTime);
                        this.innerdialogVisible = false;
                    }
                    const ddh=res.data.obj.orderId;
                    const result=await this.$http.getHttp("/ddxx/getDdxxByDdh?ddh="+ddh);
                    if(result.data.code===200){
                        const zt=result.data.obj.ddzt;
                        if(zt===2){
                            this.$message({message:'支付成功',type:'success',showClose:true});
                            this.innerdialogVisible=false;
                            this.qrcodeTime=0;
                            clearInterval(this.intervalQrcodeTime);
                        }
                    }else if(result.data.code===500){
                        if(result.data.message){
                            this.$message({message:result.data.message,type:'error'});
                        }
                    }else if(result.data.code===0){
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }
                }, 1000);
                }else if(res2.data.code===500){
                    if(res2.data.message){
                        this.$message({message:res2.data.message,type:'error'});
                    }
                }else if(res2.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        // const res=await this.$http.getHttp("/msg/verification?codeid="+codeId+"&versioncation_code="+yzm);
        // console.log(res);
        // if(res.data.code===200){
        // }else if(res.data.code===500){
        //     if(res.data.message){
        //         this.$message({message:res.data.message,type:'error'});
        //     }
        // }else if(res.status===401){
        //     this.$store.commit('initRecommend');
        //     this.$store.commit('initSchoolFind');
        //     this.$store.commit('initUserInfo');
        //     this.$store.commit('initToken');
        //     this.$message({message: '登录超时，请重新登录',type:"error"});
        //     this.$router.replace({
        //         path: '/home',
        //         query: {redirect: this.$router.currentRoute.fullPath}
        //     });
        // }else if(res.data.code===0){
        //     this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
        //     this.$store.commit('initRecommend');
        //     this.$store.commit('initSchoolFind');
        //     this.$store.commit('initUserInfo');
        //     this.$store.commit('initToken');
        //     this.$router.replace({
        //         path: '/home',
        //         query: {redirect: this.$router.currentRoute.fullPath}
        //     });
        // }
    }
},
computed:{
    ...mapState(["userInfo"])
}
};
</script>
<style lang="less" scoped>
            .buy2{width: 150px;border-radius:3px; }
            .buy2 a{background-color: #409eff;border:1px solid #409eff;width: 200px;height: 40px;overflow: hidden;display: block;border-radius:5px;
            text-align: center;font: 400 17px/40px "Microsoft Yahei",Arial,Tahoma,sans-serif;color:#fff;
            }
            .line2 {
                border: 0;
                width: 985px;
                height: 2px;
                background-image: linear-gradient(to right,transparent, #666, transparent);
            }
            #J-buyCard-mod1-slide{width:500px;}
            #J-buyCard-mod1-slide img{width:500px;}
            #J-buyCard-mod2-nav{position:relative;z-index:10000}
            .buy-price{
                color: #0000FF;
                font-size: 16px;
                font-weight: 600;
            }
            .pay-price{
                font-size: 20px;
                font-weight: 600;
                color: #FF0000;
            }
            .weixin-panel{
                width:100%;
                height:300px;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                .qrcode{
                    width:180px;
                    height:180px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    padding:10px;
                    background:url('../assets/images/wxcode-bg.png') no-repeat center;
                }
            }
</style>
