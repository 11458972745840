<template>
    <div>
    <div class="card-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>招生政策</el-breadcrumb-item>
      </el-breadcrumb>
        </div>
        <div class="schoolIntro_con23">
            <div class="majorCon2">
                <h3>{{detail.title}}</h3>
            </div>
            <div class="time-div">发布时间：<span>{{detail.createTime}}</span></div>
            <div class="txt" id="zszc" v-html="detail.zszcTxt" style="text-align:center;"></div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            detail:{
                title:'',
                zszcTxt:'',
                createTime:''
            }
        };
    },
    methods:{
        // 获取招生政策信息
        async getPolicyDetail(id){
            const res=await this.$http.getHttp("/policy/getPolicyDetail?id="+id);
            if(res.data.success){
                const data=res.data.data;
                this.detail.title=data.title;
                this.detail.zszcTxt=data.content;
                this.detail.createTime=data.createTime;
            }else if(res.data.error){
                this.$message({
                    message:res.data.error,
                    type:'error'
                });
            }
        }
    },
    async mounted(){
        const id=this.$route.query.id;
        await this.getPolicyDetail(id);
    }
};
</script>
<style scoped lang="less">
.schoolIntro_con23 {
    width: 790px;
    // width: 1100px;
    padding-top: 0;
    overflow: hidden;
    margin-left:15px;
    margin: 0 auto;
    margin-top: 20px;
    .majorCon2 {
    height: 35px;
    // border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-align: center;
    // border-bottom: 1px solid #efaf49;
    clear: left;
    h3{
        font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    // color: #efaf49;
    }
}
.txt{
    margin-bottom: 20px;
}
.time-div{
        text-align: center;
        padding-bottom: 10px;
    }
}
.card-top{
        width: 1100px;
        margin: 20px auto;
}
</style>
