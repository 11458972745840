import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import Help from '../views/help.vue';
import Contact from '../views/about/contact.vue';
import Home from '../views/home.vue';
import Buyindex from '../views/buyindex.vue';
import store from "../store/index"; // 全局注册store
import zypg from '../views/audit/index.vue';
import zjService from '../views/about/zjservice.vue';
import yhassess from '../views/about/yhassess.vue';
import about from '../views/about/about.vue';
import questions from '../views/about/questions.vue';
import regist from '../views/jbtRegist/regist.vue';
import userxx from '../views/userxx.vue';
import updatePwd from '../views/userSet/updatePwd.vue';
import majorQuery from '../views/major/majorQuery.vue';
import dataHome from '../views/dataStore/dataHome.vue';
import me from '../views/me.vue';
import updateScore from '../views/userSet/updateScore.vue';
import setPwd from '../views/userSet/setPwd.vue';
import schoolDetail from '../views/schoolDetail.vue';
import yxdb from '../views/jbxx_db.vue';
import zyzDeail from '../views/zyzDetail.vue';
import majorDeail from '../views/major/majorDetail.vue';
import qjPlan from '../views/qjPlan/zzzs.vue';
import pgResult from '../views/audit/pgResult/pgResult.vue';
import aiPay from '../views/AiPay.vue';
import qjDeail from '../views/qjPlan/yxxq.vue';
import promoteHy from '../views/jbtRegist/promoteHy.vue';
import chooseZy from '../views/audit/lrzyfa/chooseZy.vue';
import policyDetail from '../views/dataStore/policyDetail/zszcDetail.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'mian',
    redirect: "/home"
  },
  {
    path:'/help',
    name:"help",
    meta:{title:"金榜志愿通帮助文档", keepAlive: false},
    component:Help
  },
  {
    path:"/abouts/contact",
    name:"contact",
    meta:{title:"金榜志愿通", keepAlive: false},
    component:Contact
  },
  {
    path:"/home",
    name:"home",
    meta:{title:"金榜志愿通",keepAlive:false},
    component:Home
  },
  {
    path:"/buyindex",
    name:"buyindex",
    meta:{title:"金榜志愿通", keepAlive: false},
    component:Buyindex
  },
  {
    path:"/abouts/zjfw",
    name:"zjservice",
    meta:{title:"金榜志愿通", keepAlive: false},
    component:zjService
  },
  {
    path:"/abouts/yhassess",
    name:"yhassess",
    meta:{title:"金榜志愿通", keepAlive: false},
    component:yhassess
  },
  {
    path:"/abouts/about",
    name:"about",
    meta:{title:"金榜志愿通", keepAlive: false},
    component:about
  },
  {
    path:"/abouts/questions",
    name:"questions",
    meta:{title:"金榜志愿通", keepAlive: false},
    component:questions
  },
  {
    path:"/regist",
    name:"regist",
    meta:{title:"用户注册",keepAlive:false},
    component:regist
  },
  {
    path:"/userxx",
    name:"userxx",
    meta:{title:"金榜通系统用户协议", keepAlive: false},
    component:userxx
  },
  {
    path:"/findPwd",
    name:"updatePwd",
    meta:{title:"找回密码",requiresAuth: false, keepAlive: false},
    component:updatePwd
  },
  {
    path:"/schoolfinder",
    name:"schoolfinder",
    meta:{title:"院校查询",requiresAuth: true, keepAlive: false},
    component:()=>import('../views/yxcx.vue')
  },
  {
    path:"/schoolRecommend",
    name:"schoolRecommend",
    meta:{title:"院校推荐",requiresAuth: true, keepAlive: false},
    component:()=>import('../views/yxtj.vue')
  },
  {
    path:"/majorquery",
    name:"majorquery",
    meta:{title:"专业查询",requiresAuth: true, keepAlive: false},
    component:majorQuery
  },
  {
    path:"/datahome",
    name:"datahome",
    meta:{title:"数据查询",requiresAuth:true,keepAlive:false},
    component:dataHome
  },
  {
    path:"/me",
    name:"me",
    meta:{title:"个人中心"},
    component:me,
    children:[
      {
        path:"updateScore",
        name:"updateScore",
        meta:{title:"修改分数",requiresAuth:true,keepAlive:false},
        component:updateScore
      },
      {
        path:"updatePwd",
        name:"updatePwd",
        meta:{title:"修改密码",requiresAuth:true,keepAlive:true},
        component:setPwd
      },
      {
        path:"/me",
        redirect:"/me/updateScore"
      }
    ]
  },
  {
    path:"/schoolDetail",
    name:"schoolDetail",
    meta:{title:"院校详情",requiresAuth:true,keepAlive:true},
    component:schoolDetail
  },
  {
    path:"/zypg",
    name:"zypg",
    meta:{title:"志愿评估",requiresAuth:true,keepAlive:false},
    component:zypg
  },
  {
    path:"/db/jbxx",
    name:"yxdb",
    meta:{title:"院校对比",requiresAuth:true,keepAlive:true},
    component:yxdb
  },
  {
    path:"/zyzDetail",
    name:"zyzDetail",
    meta:{title:"院校专业组详情",requiresAuth:true,keepAlive:true},
    component:zyzDeail
  },
  {
    path:"/majorDetail",
    name:"majorDetail",
    meta:{title:"专业详情",requiresAuth:true,keepAlive:true},
    component:majorDeail
  },
  {
    path:"/qjPlan",
    name:"qjPlan",
    meta:{title:"强基计划",requiresAuth:true,keepAlive:true},
    component:qjPlan
  },
  {
    path:"/getPgResult",
    name:"pgResult",
    meta:{title:"评估报告",requiresAuth:true,keepAlive:false},
    component:pgResult
  },
  {
    path:"/payGateWay",
    name: "AiPay",
    meta: { title: "支付宝支付" },
    component:aiPay
  },
  {
    path:'/qjPlan/detail',
    name:'qjdetail',
    meta:{title:"院校详情",requiresAuth:true,keepAlive:false},
    component:qjDeail
  },
  {
    path:'/promote',
    name:'promte',
    meta:{title:'游客升级会员',keepAlive:false},
    component:promoteHy
  },
  {
    path:'/chooseZy',
    name:'chooseZy',
    meta:{title:'选择专业',keepAlive:false},
    component:chooseZy
  },
  {
    path:'/policyDetail',
    name:'policyDetail',
    meta:{title:'招生政策详情',requiresAuth:true,keepAlive:false},
    component:policyDetail
  }
  // {
    // path: '/about',
    // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  routes
});
// 全局配置
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.state.userInfo.yhid) {
      // 需要登录后查看的页面判断user是否登录
      next();
    } else {
      Vue.prototype.$message({
        type: "warning",
        message: "您还没有登录，请先登录系统",
        duration: "1000"
      });
      next("/");
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});
export default router;
